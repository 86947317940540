import 'react-h5-audio-player/lib/styles.css'

import { FooterType, GlobalSeo } from '../types/types'
import { LinkedinShareButton, TwitterShareButton } from 'react-share'
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'

import AudioPlayer from 'react-h5-audio-player'
import ButtonStandard from '../components/atoms/ButtonStandard'
import { Helmet } from 'react-helmet'
import IconLinkedIn from '../components/atoms/Icon-LinkedIn'
import IconTwitter from '../components/atoms/Icon-Twitter'
import Img from 'gatsby-image'
import Layout from '../layout/Layout'
import { Link } from 'gatsby'
import SideArrow from '../components/atoms/SideArrow'
import { plyrOptions } from '../utils/global'

interface ArticleTemplateProps {
  pageContext: {
    article: {
      title: string
      author: string
      date: string
      slug: string
      transparentHeader: boolean
      transparentHeaderLight: boolean
      category: {
        category: string
      }
      seo: {
        title: string
        description: string
        image: {
          url: string
        }
      }
      articleType: any
    }
    footer: FooterType
    globalSeo: GlobalSeo
  }
  data: any
  location: any
}

const ArticleTemplate = ({ pageContext, location }: ArticleTemplateProps): JSX.Element => {
  const { transparentHeader, transparentHeaderLight, seo } = pageContext.article
  const { article, globalSeo } = pageContext
  const { siteName, fallbackSeo } = globalSeo
  const articleType = article.articleType[0]
  const btnText = articleType.buttonText || 'Read Now'

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // eslint-disable-next-line
      const Plyr = require('plyr')
      Array.from(document.querySelectorAll('#player-controls')).map((p) => new Plyr(p, plyrOptions))
    }
  }, [articleType])

  let seoData = {
    title: `${siteName} - ${article.title}`,
    description: fallbackSeo.description,
    image: fallbackSeo.image.url,
    url: 'https://sayers.group/insights/',
  }

  if (seo) {
    seoData = {
      title: seo.title ? `${siteName} - ${seo.title}` : `${siteName} - ${article.title}`,
      description: seo.description ? seo.description : fallbackSeo.description,
      image: seo.image ? seo.image.url : fallbackSeo.image.url,
      url: 'https://sayers.group/insights/' + pageContext.article.slug,
    }
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>{seoData.title}</title>
        <meta name="msvalidate.01" content="DCA06C782ADDDB0B6E1250E3BA1B5CBA" />
        {seoData.description && <meta name="description" content={seoData.description} />}
        {seoData.description && <meta property="og:description" content={seoData.description} />}
        {seoData.title && <meta property="og:title" content={seoData.title} />}
        {seoData.url && <meta property="og:url" content={seoData.url} />}
        {seoData.image && <meta name="image" content={seoData.image} />}
        {seoData.image && <meta name="image" property="og:image" content={seoData.image} />}
        <meta name="cf-2fa-verify" content="dEBO8TzWA1pxGJnSi7T9" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout transparent={transparentHeader} darkMode={transparentHeaderLight}>
        <>
          {articleType.__typename === 'DatoCmsArticleVideo' ? (
            <section className="insights-article__media">
              <div className="insights-article__media--inner container">
                {articleType.videoUrl ? (
                  <div className="plyr__video-embed" id="player-controls">
                    <iframe src={`${articleType.videoUrl}`} allowFullScreen allow="autoplay"></iframe>
                  </div>
                ) : (
                  <video controls>
                    <source src={articleType.video.url} type="video/mp4" />
                  </video>
                )}
              </div>
            </section>
          ) : (
            <section className="insights-article__hero">
              {articleType.bannerImage ? (
                <Img className="insights-article__hero--img" fluid={articleType.bannerImage?.fluid} />
              ) : articleType.tileImage ? (
                <Img className="insights-article__hero--img" fluid={articleType.tileImage?.fluid} />
              ) : null}
            </section>
          )}
        </>
        <section className="insights-article">
          <div className="insights-article__inner container">
            <Link to="/insights" className="insights-article__return-btn">
              <SideArrow iconClass="insights-article__return-btn--icon" />
              <h3 className="insights-article__return-btn--title">all insights</h3>
            </Link>
            <h2 className="insights-article__title">{article.title}</h2>
            <div className="insights-article__social-icons">
              <LinkedinShareButton url={location.href} title={article.title}>
                <IconLinkedIn />
              </LinkedinShareButton>
              <TwitterShareButton url={location.href} title={article.title}>
                <IconTwitter />
              </TwitterShareButton>
            </div>
            <p className="h4 insights-article__details">
              {article.author && `By ${article.author} |`}
              {new Date(article.date).toLocaleDateString().split('/').join('.')}
            </p>
            {articleType.__typename === 'DatoCmsArticlePodcast' && <AudioPlayer src={articleType?.audioFile?.url} />}
            {articleType.body && (
              <article
                className="insights-article__body"
                dangerouslySetInnerHTML={{ __html: `<div>${articleType.body}</div>` }}
              />
            )}
            {articleType.linkToMedia && (
              <ButtonStandard
                href={articleType.linkToMedia.url}
                text={btnText}
                isExternalLink={true}
                className="insights-article__external-link-btn"
              />
            )}
            {articleType.url && (
              <ButtonStandard
                href={articleType.url}
                text={btnText}
                isExternalLink={true}
                className="insights-article__external-link-btn"
              />
            )}
          </div>
        </section>
      </Layout>
    </>
  )
}

export default ArticleTemplate
